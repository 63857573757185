import React from "react";
import "./less/content2n.less";
import { Icon } from "antd";
class Content extends React.Component {
  render() {
    return (
      <div className="sec-down">
        <div className="outer-right-1">
          <div className="p-content">
            <p className="p-title">联系我们</p>
            <span className="p">
              <Icon
                type="customer-service"
                theme="outlined"
                style={{ color: "#4e5ff7", marginRight: "30px" }}
              />
              客户支持：
              <span className="p-text">400-698-5656</span>
            </span>
            <span className="p" style={{ marginTop: "20px" }}>
              <Icon
                type="team"
                theme="outlined"
                style={{ color: "#4e5ff7", marginRight: "30px" }}
              />
              商务合作 / 品牌公关：limengchang@hotmail.com
            </span>
          </div>
          <div className="p-content">
            <p className="p-title">办公地址</p>
            <span className="p">
              <Icon
                type="environment"
                theme="outlined"
                style={{ color: "#4e5ff7", marginRight: "30px" }}
              />
              <strong className="inb-black">北京总部</strong>
              北京市昌平区回龙观东大街首开广场405-3
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
