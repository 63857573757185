import React from "react";
import { Row, Col } from "antd";
import logo from "../../img/Home/logo2.png";
import "./less/business.less";
class Business extends React.PureComponent {
  render() {
    return (
      <div className="business-box">
        <div>
          <span className="business-title">业务范围</span>
          <p className="title-line" />
        </div>
        <img src={logo} className="logo" />
        <div className="dot1" />
        <div className="dot2" />
      </div>
    );
  }
}

export default Business;
