import React from "react";
import { enquireScreen } from "enquire-js";

import flower from "../../../img/solution/flowchart.png";
import Content0 from "./Content0";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content5 from "./Content5";
import Forwarder from "./Forwarder";

import { BackTop } from "antd";

import "./less/antMotion_style.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Content0
        id="content_2_0"
        key="content_2_0"
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="content_3_0"
        key="content_3_0"
        isMobile={this.state.isMobile}
      />,
      <Content2
        id="content_2_1"
        key="content_2_1"
        isMobile={this.state.isMobile}
      />,
      <Content3
        id="content_3_0"
        key="content_3_0"
        isMobile={this.state.isMobile}
      />,
      <Content5
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />
    ];
    return (
      <div className="templates-wrapper">
        <Forwarder />
        <div style={{ width: "100%", height: "60vh" }}>
          <img src={flower} style={{ display: "block", margin: "0 auto" }} />
        </div>
        {children}
        <BackTop />
      </div>
    );
  }
}
