import React from "react";
import { enquireScreen } from "enquire-js";
import { BackTop } from "antd";

import Content1 from "./Content1";
import Content2n from "./Content2n";
import Content3n from "./Content3n";
import Content0n from "./Content0n";

import "./less/antMotion_style.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Content0n />,
      <Content1
        id="content_7_0"
        key="content_7_0"
        isMobile={this.state.isMobile}
      />,
      <Content2n
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />,
      <Content3n />
    ];
    return (
      <div className="templates-wrapper">
        <BackTop />
        {children}
      </div>
    );
  }
}
