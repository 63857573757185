import React from "react";
import wxpublic from "../../img/Home/wx-public.jpg";
export const Banner30DataSource = {
  wrapper: { className: "banner3 jos8j1jraor-editor_css" },
  textWrapper: {
    className: "banner3-text-wrapper jos8k1kzrvc-editor_css",
    children: [
      {
        name: "slogan",
        className: "banner3-slogan jos8l1ukh7-editor_css",
        children: "E运通",
        texty: true
      },
      {
        name: "name",
        className: "banner3-name",
        children: (
          <span>
            <p>智慧物流，智慧你的生活</p>
          </span>
        )
      }
    ]
  }
};
export const Content50DataSource = {
  wrapper: {
    className: "home-page-wrapper content5-wrapper josbkfc9p7s-editor_css"
  },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>产品与服务</p>
          </span>
        ),
        className: "title-h1"
      },
      {
        name: "titleLine",
        children: <p />,
        className: "title-black-line"
      }
    ]
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      },
      {
        name: "block4",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block5",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      },
      {
        name: "block6",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block7",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      }
    ]
  }
};
export const Content00DataSource = {
  wrapper: {
    className: "home-page-wrapper content0-wrapper josa1478br-editor_css"
  },
  page: { className: "home-page content0 josa6c82nmi-editor_css" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>我们的优势</p>
          </span>
        ),
        className: "undefined josa1ts0lkh-editor_css"
      },
      {
        name: "titleLine",
        children: <p />,
        className: "title-line"
      }
    ]
  },
  block: {
    className: "block-wrapper",
    children: [
      {
        name: "block0",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon",
            children: "https://www.e-yuntong.com/static/img/advantage1.png"
          },
          title: {
            className: "content0-title josa77owa9-editor_css",
            children: (
              <span>
                <p>贴心</p>
              </span>
            )
          },
          content: {
            children: (
              <span>
                <p>
                  发货、预到货短信提醒。手机端实时货物在途查询，有效提供对客户的服务能力
                </p>
              </span>
            ),
            className: "undefined josa7i1hl5a-editor_css"
          }
        }
      },
      {
        name: "block1",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon",
            children: "https://www.e-yuntong.com/static/img/advange2.png"
          },
          title: {
            className: "content0-title josa7pb11j-editor_css",
            children: (
              <span>
                <p>协同</p>
              </span>
            )
          },
          content: {
            children: (
              <span>
                <p>
                  对接货主、承运商，实现信息的互通；查询系统多维度，销售人员直接根据销售单查询，方便快捷，直观展示，减少部门中间环节的沟通
                </p>
              </span>
            ),
            className: "undefined josa7zmr8i-editor_css"
          }
        }
      },
      {
        name: "block2",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon",
            children: "https://www.e-yuntong.com/static/img/advantage3.png"
          },
          title: {
            className: "content0-title josa85aesu-editor_css",
            children: (
              <span>
                <p>高效</p>
              </span>
            )
          },
          content: {
            children: (
              <span>
                <p>
                  打通信息流，货物中转状态实时可见，电子运单自动分发流转，节省人力、时间，提升企业信息化水平
                </p>
              </span>
            ),
            className: "undefined josa8bg65wb-editor_css"
          }
        }
      },
      {
        name: "block~josa5qiran6",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon",
            children: "https://www.e-yuntong.com/static/img/advange4.png"
          },
          title: {
            className: "content0-title jredsmj2z5m-editor_css",
            children: "移动"
          },
          content: {
            children: (
              <span>
                <p>
                  车辆在途全程可观、可控、可追溯，异常智能报警，提高对货物运输全程的管控力
                </p>
              </span>
            ),
            className: "undefined jredptyxkk-editor_css"
          }
        }
      },
      {
        name: "block~josa5s1etr",
        className: "block",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon",
            children: "https://www.e-yuntong.com/static/img/advantage4.png"
          },
          title: {
            className: "content0-title jredsmj2z5m-editor_css",
            children: "灵活"
          },
          content: {
            children: (
              <span>
                <p>全系统可定制化，根据需求配置，实现各司其职，公司品牌化</p>
              </span>
            ),
            className: "undefined jredptyxkk-editor_css"
          }
        }
      },
      {
        name: "block~josa5tms13k",
        className: "block jredqpm-editor_css",
        md: 8,
        xs: 24,
        children: {
          icon: {
            className: "icon jredil1hkdr-editor_css",
            children: "https://www.e-yuntong.com/static/img/advange6.png"
          },
          title: {
            className: "content0-title jredsmj2z5m-editor_css",
            children: (
              <span>
                <p>大数据</p>
              </span>
            )
          },
          content: {
            children: (
              <span>
                <p>
                  一站式、全周期大数据协同工作平台，PB级数据处理、毫秒级数据分析工具。
                </p>
              </span>
            ),
            className: "undefined jredptyxkk-editor_css"
          }
        }
      }
    ]
  }
};
export const Content51DataSource = {
  wrapper: { className: "home-page-wrapper content5-wrapper" },
  page: { className: "home-page content5" },
  OverPack: { playScale: 0.3, className: "" },
  titleWrapper: {
    className: "title-wrapper",
    children: [
      {
        name: "title",
        children: (
          <span>
            <p>合作伙伴</p>
          </span>
        ),
        className: "title-h1"
      },
      {
        name: "titleLine",
        children: <p />,
        className: "title-black-line"
      }
    ]
  },
  block: {
    className: "content5-img-wrapper",
    gutter: 16,
    children: [
      {
        name: "block0",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block1",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      },
      {
        name: "block2",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://t.alipayobjects.com/images/rmsweb/T11aVgXc4eXXXXXXXX.svg"
          },
          content: { children: "Ant Design" }
        }
      },
      {
        name: "block3",
        className: "block",
        md: 6,
        xs: 24,
        children: {
          wrapper: { className: "content5-block-content" },
          img: {
            children:
              "https://zos.alipayobjects.com/rmsportal/faKjZtrmIbwJvVR.svg"
          },
          content: { children: "Ant Motion" }
        }
      }
    ]
  }
};
export const Footer10DataSource = {
  wrapper: { className: "home-page-wrapper footer1-wrapper" },
  OverPack: { className: "footer1", playScale: 0.2 },
  block: {
    className: "home-page",
    children: [
      {
        name: "block0",
        xs: 24,
        md: 5,
        className: "block",
        title: {
          children: "公司信息"
        },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <a href="#/about">公司简介</a>{" "}
              </p>{" "}
              <p>
                {" "}
                <a href="#/about">企业理念</a>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block1",
        xs: 24,
        md: 5,
        className: "block",
        title: { children: "产品与解决方案" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <a href="#/solution/customer">货主端解决方案</a>{" "}
              </p>{" "}
              <p>
                {" "}
                <a href="#/solution/forwarder">第三方物流解决方案</a>{" "}
              </p>{" "}
              <p>
                {" "}
                <a href="#/solution/park">物流园区解决方案</a>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block2",
        xs: 24,
        md: 5,
        className: "block",
        title: { children: "合作伙伴" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <a href="#/">客户</a>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block3",
        xs: 24,
        md: 5,
        className: "block",
        title: { children: "加入我们" },
        content: {
          children: (
            <span>
              <p>
                {" "}
                <a href="https://www.e-yuntong.com/static/checklist.html">
                  技能表
                </a>{" "}
              </p>
            </span>
          )
        }
      },
      {
        name: "block4",
        xs: 24,
        md: 4,
        className: "block",
        title: { children: "关注我们" },
        content: {
          children: (
            <span>
              <img src={wxpublic} style={{ width: "100px" }} />
            </span>
          )
        }
      }
    ]
  },
  copyrightWrapper: { className: "copyright-wrapper" },
  copyrightPage: { className: "home-page" },
  copyright: {
    className: "copyright",
    children: (
      <span>
        <a href="#privacy">隐私政策</a> | <a href="#agreement">服务协议</a> |
        网站许可证：津ICP备18003968号
      </span>
    )
  }
};
export const Nav00DataSource = {
  wrapper: {
    className: "header0 home-page-wrapper jos1owotoeo-editor_css mynavClass"
  },
  page: { className: "home-page" },
  logo: {
    className: "header0-logo",
    children: "https://www.e-yuntong.com/static/img/logo.png"
  },
  Menu: {
    className: "header0-menu",
    children: [
      {
        name: "item0",
        a: {
          children: "首页",
          href: "",
          className: "undefined jos8z6gplk-editor_css",
          target: ""
        },
        className: "undefined jos9ba8x3o-editor_css"
      },
      { name: "item1", a: { children: "产品服务", href: "#/product" } },
      { name: "item2", a: { children: "解决方案", href: "" } },
      { name: "item3", a: { children: "关于我们", href: "#/about" } },
      { name: "item~jos97dh82r", a: { children: "招贤纳士", href: "#/join" } },
      {
        name: "item~jos97hzci",
        a: { children: "登录", href: "https://etms.e-yuntong.com" }
      }
    ]
  },
  mobileMenu: { className: "header0-mobile-menu" }
};
