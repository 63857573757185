import React from "react";
import { Row, Col } from "antd";
import bubble from "../../img/Home/bubble.png";
import pc from "../../img/Home/product-pc.png";
import app from "../../img/Home/product-app.png";
import wechat from "../../img/Home/product-wechat.png";
import block from "../../img/Home/block.png";
import "./less/content50.less";
class Content5 extends React.PureComponent {
  render() {
    return (
      <div className="product-container">
        <div className="product-content">
          <span className="product-title">产品与服务</span>
          <p className="title-black-line" />
          <Row style={{ marginTop: "40px" }}>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={pc} />
                  <p>ETMS系统</p>
                  <span>
                    PC端管理系统，支持管理司机，客户，配载运单，结算运费
                  </span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={app} />
                  <p>货主端APP</p>
                  <span>
                    下单，查单，考核，历史数据统计，货物位置追踪，一手掌握
                  </span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={wechat} />
                  <p>承运商APP</p>
                  <span>接单，派车，追踪位置，实施审核，数据统计</span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={wechat} />
                  <p>司机端APP</p>
                  <span>抢单，装车，配货，签收，异常上报，解决沟通问题</span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={pc} />
                  <p>ETMS查询系统</p>
                  <span>让客户有便捷的查单通道，不再找不到，不再繁琐</span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={app} />
                  <p>小程序</p>
                  <span>
                    支持三端多角色登录，让司机交接更便捷，解决司机流动性大的痛点
                  </span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={wechat} />
                  <p>BI大数据分析</p>
                  <span>
                    大屏展示更项数据：发货量，单子分布，月均比例，全面分析公司运营情况
                  </span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
            <Col
              className="product-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="product-item">
                <img className="product-bg" src={bubble} />
                <div className="product-content-box">
                  <img className="product-img" src={wechat} />
                  <p>56BOSS</p>
                  <span>
                    物流老板的强势神器，一手掌握公司运营情况，做你的贴身秘书管家
                  </span>
                  <img className="block" src={block} />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Content5;
