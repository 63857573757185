import React from "react";
import { findDOMNode } from "react-dom";
import TweenOne from "rc-tween-one";
import { Menu } from "antd";
import hotline from "../../img/Home/hotline.png";
import telphone from "../../img/Home/telphone.png";

const Item = Menu.Item;
const SubMenu = Menu.SubMenu;
class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneOpen: false,
      menuHeight: 0,
      selected: ["0"]
    };
  }

  componentWillMount = () => {
    this.changeHash();
    window.addEventListener("hashchange", () => {
      this.changeHash();
    });
  };

  changeHash = () => {
    let hash = window.location.hash;
    if (hash === "#/product") {
      this.setState({
        selected: ["1"]
      });
    } else if (hash === "#/solution/customer") {
      this.setState({
        selected: ["customer"]
      });
    } else if (hash === "#/solution/forwarder") {
      this.setState({
        selected: ["forwarder"]
      });
    } else if (hash === "#/solution/park") {
      this.setState({
        selected: ["park"]
      });
    } else if (hash === "#/about") {
      this.setState({
        selected: ["3"]
      });
    } else if (hash === "#/join") {
      this.setState({
        selected: ["4"]
      });
    } else {
      this.setState({
        selected: ["0"]
      });
    }
  };

  phoneClick = () => {
    const menu = findDOMNode(this.menu);
    const phoneOpen = !this.state.phoneOpen;
    this.setState({
      phoneOpen,
      menuHeight: phoneOpen ? menu.scrollHeight : 0
    });
  };

  render() {
    const { ...props } = this.props;
    const { dataSource, isMobile } = props;
    delete props.dataSource;
    delete props.isMobile;
    const { menuHeight, phoneOpen } = this.state;
    const navData = dataSource.Menu.children;
    const navChildren = Object.keys(navData).map((key, i) => {
      if (navData[key].a.children === "解决方案") {
        return (
          <SubMenu
            title={<span className="submenu-title-wrapper">解决方案</span>}
          >
            <Item key="customer">
              <a href="#/solution/customer">货主端</a>
            </Item>
            <Item key="forwarder">
              <a href="#/solution/forwarder">第三方物流</a>
            </Item>
            <Item key="park">
              <a href="#/solution/park">物流园区</a>
            </Item>
          </SubMenu>
        );
      }
      return (
        <Item key={i.toString()} {...navData[key]}>
          <a
            {...navData[key].a}
            href={navData[key].a.href}
            target={navData[key].a.target}
          >
            {navData[key].a.children}
          </a>
        </Item>
      );
    });
    return (
      <TweenOne
        component="header"
        animation={{ opacity: 0, type: "from" }}
        {...dataSource.wrapper}
        {...props}
      >
        <div
          {...dataSource.page}
          className={`${dataSource.page.className}${phoneOpen ? " open" : ""}`}
        >
          <TweenOne
            animation={{ x: -30, type: "from", ease: "easeOutQuad" }}
            {...dataSource.logo}
          >
            <img width="100%" src={dataSource.logo.children} alt="img" />
          </TweenOne>
          <div
            style={{
              position: "fixed",
              top: "300px",
              right: "0px",
              padding: "10px 5px",
              backgroundColor: "rgba(29, 27, 27, 0.75)",
              transform: "translate(0px, 0px)"
            }}
            className="isphoneshow"
          >
            <a href="tel://4006985656">
              <img
                src={telphone}
                alt="热线电话：4006985656"
                class="isCallShow"
                style={{ display: "block", margin: "5px auto", width: "25px" }}
              />
              <p
                style={{
                  color: "rgb(255, 255, 255)",
                  borderBottom: "1px solid rgb(255, 255, 255)",
                  paddingBottom: "5px"
                }}
              >
                电话咨询
              </p>
            </a>
          </div>
          {isMobile && (
            <div
              {...dataSource.mobileMenu}
              onClick={() => {
                this.phoneClick();
              }}
            >
              <em />
              <em />
              <em />
            </div>
          )}
          <TweenOne style={{ float: "right" }} className="tel">
            <img
              src={hotline}
              style={{ marginTop: "12px", marginLeft: "12px" }}
            />
          </TweenOne>
          <TweenOne
            {...dataSource.Menu}
            animation={{ x: 30, type: "from", ease: "easeOutQuad" }}
            ref={c => {
              this.menu = c;
            }}
            style={isMobile ? { height: menuHeight } : { float: "right" }}
          >
            <Menu
              mode={isMobile ? "inline" : "horizontal"}
              theme={isMobile ? "dark" : "default"}
              selectedKeys={this.state.selected}
            >
              {navChildren}
            </Menu>
          </TweenOne>
        </div>
      </TweenOne>
    );
  }
}

export default Header;
