import React from 'react';
import PropTypes from 'prop-types';
import TweenOne from 'rc-tween-one';
import QueueAnim from 'rc-queue-anim';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import culture from '../../img/about/timg.jpg'
import service from '../../img/about/service.png'
import mission from '../../img/about/mission.png'
import vision from '../../img/about/vision.png'

class Content extends React.Component {

  static propTypes = {
    className: PropTypes.string,
    id: PropTypes.string,
  };

  static defaultProps = {
    className: 'content5',
  };

  getBlockChildren = (item, i) =>
    (<li
      key={i}
      id={`${this.props.id}-block${i}`}
    >
      <span>
        <img src={item.img} width="100%" />
      </span>
      <h2>{item.title}</h2>
      <p>{item.content}</p>
    </li>);


  render() {
    const props = { ...this.props };
    const isMobile = props.isMobile;
    const dataSource = [
      { img: mission, title: '使命', content: '让中国物流人能获得与世界一流物流企业一样的信息化服务' },
      { img: vision, title: '愿景', content: '成为最受人尊敬的物流服务专家' },
      { img: service, title: '服务理念', content: '客户成功，才是我们的成功' },
    ];
    const ulChildren = dataSource.map(this.getBlockChildren);
    delete props.isMobile;
    const queue = isMobile ? 'bottom' : 'left';
    const imgAnim = isMobile ? { y: 30, opacity: 0, delay: 400, type: 'from', ease: 'easeOutQuad' }
      : { x: 30, opacity: 0, type: 'from', ease: 'easeOutQuad' };
    return (
      <div {...props} className="content-template-wrapper content-half-wrapper">
        <OverPack
          className={`content-template ${props.className}`}
          location={props.id}
        >
          <QueueAnim
            className={`${props.className}-text`}
            key="text"
            type={queue}
            leaveReverse
            ease={['easeOutQuad', 'easeInQuad']}
            id={`${props.id}-textWrapper`}
          >
            <h1
              key="h1"
              id={`${props.id}-title`}
            >
              企业文化
            </h1>
            <p
              key="p"
              id={`${props.id}-content`}
            >
              致力于提升数十万家物流公司信息化水平，未来重点为物流公司提供信息流、物流、资金流的一站式解决方案
            </p>
            <QueueAnim
              component="ul"
              key="ul" type={queue}
              id={`${props.id}-ul`}
              ease="easeOutQuad"
              style={{marginTop:0}}
            >
              {ulChildren}
            </QueueAnim>
          </QueueAnim>
          <TweenOne
            className={`${props.className}-img`}
            key="img"
            animation={imgAnim}
            id={`${props.id}-img`}
            resetStyle
          >
            <img width="100%" src={culture} />
          </TweenOne>
        </OverPack>
      </div>
    );
  }
}


export default Content;
