import React from "react";
import ReactDOM from "react-dom";
import { enquireScreen } from "enquire-js";
import { BackTop } from "antd";

import Content0 from "./Content0";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Content3 from "./Content3";
import Content4 from "./Content4";
import Content5 from "./Content5";

import "./less/antMotion_style.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class Product extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Content0
        id="content_0_0"
        key="content_0_0"
        isMobile={this.state.isMobile}
      />,
      <Content5
        id="content_2_0"
        key="content_2_0"
        isMobile={this.state.isMobile}
      />,
      <Content3
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />,
      <Content4
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />,
      <Content2
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="content_4_0"
        key="content_4_0"
        isMobile={this.state.isMobile}
      />
    ];
    return (
      <div className="templates-wrapper">
        <BackTop />
        {children}
      </div>
    );
  }
}
