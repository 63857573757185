import React from 'react';
import { BackTop } from 'antd';
import './agreement.less';


export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
        <div  className="agree-content">
        <BackTop />
        <h3 style={{textAlign:'center',margin:'25px 0'}}>E运通用户服务协议</h3>
       <div>   E运通服务（以下简称本服务）是由易运通(天津)信息技术有限公司（以下简称本公司）向E运通用户提供的软件系统（以下简称本系统）及(或)附随的运费、保险费等费用代收代付的中介服务。本协议由您和本公司签订。
       
       <br />一、 接受条款
       <br />    您在E运通（以下简称本网站）的注册、登录、使用等行为将视为对本协议的接受即表示您已经同意与易运通(天津)信息技术有限公司订立本协议，且您将受本协议的条款和条件 (“条款”) 约束。本《协议》可由本公司随时更新，更新后的协议条款一旦公布即代替原来的协议条款，恕不再另行通知，您可在本网站查阅最新版协议条款。在本公司修改《协议》条款后，如果您不接受修改后的条款，请立即停止使用E运通提供的服务，您继续使用E运通提供的服务将被视为已接受了修改后的协议。当您与本公司发生争议时，应以最新的“条款”为准。
       
       <br />二、服务对象
       <br />车主及物流公司只有符合下列条件之一的自然人或法人才能申请成为本网站会员，可以享受本网站的服务：
       <br />    1.取得道路运输管理机构颁发的道路运输经营许可证。
       <br />    2.从事危险货物运输经营的，须取得道路运输管理机构颁发的危险品道路运输经营许可证。
       
       <br />货主只有符合下列条件之一的自然人或法人才能申请成为本网站会员，可以享受本网站的服务：
       <br />    1.年满十八岁，并具有民事权利能力和民事行为能力的自然人；
       <br />    2.根据中国法律、法规、行政规章成立并合法存在的机关、企事业单位、社团组织和其他组织。

       <br />三、承运方（车主及物流公司）的基本权利与义务
       <br />    1、您有权根据本协议及本网站发布的相关规则，利用E运通发布车源信息、查询货源信息、参与报价、发布货源信息、查询车源信息、开设网上专线、选择合适的车辆交易。参加本网站的有关活动及有权享受本网站提供的其他有关资讯及信息服务；
       <br />    2、您须自行负责自己的会员账号和密码，且须对在会员账号密码下发生的所有活动（包括但不限于发布货源信息、网上点击同意各类协议、规则、选择车辆承运货物、电子钱包支付等）承担责任。会员有权根据需要更改登录密码。因会员的过错导致的任何损失由会员自行承担，该过错包括但不限于：不按照交易提示操作，未及时进行交易操作，遗忘或泄漏密码，密码被他人破解，您使用的计算机被他人侵入；
       <br />    3、您应当向本网站提供真实准确完整的注册信息，包括但不限于营业执照、自然人身份证、司机驾驶证和车辆行驶证、车辆运营证等。同时，您也应当在相关资料实际变更时及时更新有关注册资料。您进一步同意其一旦将信息或资料在使用服务时提供，则表示同意放弃对此信息或资料所享有的隐私权、版权和相关财产权利。
       <br />    4、您不得以任何形式擅自转让或授权他人使用自己在本网站的会员帐号；
       <br />    5、您有义务确保在本网站上发布的车辆信息、驾驶员信息真实、准确无误，包括但不限于车辆的行驶证、驾驶证、道路运输经营许可证；
       <br />    6、您在本网站交易中应当遵守诚实信用原则，不得以干预或操纵交易等不正当竞争方式扰乱网上交易秩序，不得从事与网上交易无关的不当行为；
       <br />    7、您不应采取不正当手段（包括但不限于虚假需求、互换好评等方式）提高自身或他人信用度，或采用不正当手段恶意评价其他会员，降低其他会员信用度；
       <br />    8、您承诺自己在使用本网站实施的所有行为遵守法律、法规、行政规章和本网站的相关规定以及各种社会公共利益或公共道德。如有违反导致任何法律后果的发生，您将自行独立承担相应的法律责任；
       <br />    9、您在本网站网上交易过程中如与其他会员因交易产生纠纷，可以请求本网站从中予以协调处理。您如发现其他会员有违法或违反本协议的行为，可以向本网站举报；
       <br />    10、您不得使用以下方式登录网站或破坏网站所提供的服务：
       <br />    A、以任何机器人软件、蜘蛛软件、爬虫软件、刷屏软件或其它自动方式访问或登录本网站；
       <br />    B、通过任何方式对本公司内部结构造成或可能造成不合理或不合比例的重大负荷的行为；
       <br />    C、通过任何方式干扰或试图干扰网站的正常工作或网站上进行的任何活动。
       <br />    11、您同意接收来自本网站的信息，包括但不限于活动信息、交易信息、促销信息等。
       <br />    12、您同意将您及车辆驾驶员的手机的地理位置分享给本网站，本网站有权利将驾驶员的手机位置开放给货主，以便货主与您放心交易。
       
       <br />四、承运方（车主及物流公司）在交易过程中的具体义务：
       <br />1、您及您的司机对货源信息做出的报价，一旦中标，您不得以任何理由拒绝承运，包括但不限于嫌弃运费低、距离发货地较远无法在承诺的时间前往装车。
       <br />2、在货源信息规定的期限内，您或您的司机须前往指定的地点提取货物，在装货过程中，驾驶员应负责进行监装，对装货过程中的不当操作有责任指出并纠正，装车完毕要求发货人开具具有法律效力的出库凭证随车带走；在货源信息规定的期限内，将货物运到指定的地点和收货人完成交接，并要求其开具具有法律效力的入库凭证随车带走。对于货主需要返回原件签收单的，应以快递的方式将签收单原件第一时间邮寄给收件人。
       <br />3、您对承运的货物负有安全责任，若违反以下条款，货主要求支付相应的违约金或由此造成其他损失时，由您自行承担全部赔偿责任： 
       <br />   （1）不按约定的时间和要求配车、发运，逾期达到。
       <br />   （2）将货物错运到卸货地点或收货人，应无偿运至合同规定的到货地点或收货人。
       <br />   （3）运输过程中货物灭失、短少、变质、污染、损坏，您应按货物的实际损失（包括包装费、运杂费）赔偿货主。在符合法律和协议规定条件下的运输，由于下列原因造成货物灭失、短少、变质、污染、损坏的，您不承担违约责任：
       <br />     （a）不可抗力；
       <br />     （b）货物本身的自然属性；
       <br />     （c）货物的合理损耗；
       <br />     （d）货主或收货方本身的过错。
       <br />4、您安全准时地将货物送达后有权获得运费，在运输途中发生的其他一切费用（包括但不限于过路、过桥费）全部由您负担；
       <br />5、您应当自行承担因交易产生或取得的相关费用，包括但不限于通讯及交易服务费，并依法纳税；
       
       <br />五、货主角色的权利和义务：
       <br />1、您应通知发货人仔细核对承运车辆的车牌号、司机的姓名和身份证号码与中标车辆信息是否一致，并复印或拍照留存司机的身份证、驾驶证、车辆行驶证和车辆营运证等证件。因发货人误认车辆导致错装的，由您自行承担损失；
       <br />2、您的货物必须是自有物品或有合法来源。倘若您的货物名称、数量、性质与货源信息的货物名称、数量、性质不符，或属禁运物品的，在运输过程中发生被执法部门查扣、罚款等情形的，所以责任由您自己承担。由于在货物中夹带、匿报危险货物，而招致货物破损、爆炸，造成人身伤亡的，您应承担由此造成的一切责任；
       <br />3、您的货物按国家或者地方法律法规规定需要包装的，应当按规定的标准包装。没有统一包装标准的，应根据保证货物运输安全和有利于实现合同目的原则进行包装。否则，因此造成的经济损失由您承担。由于货物包装缺陷产生破损，致使其他货物或运输工具、机械设备被污染腐蚀、损坏，造成人身伤亡的，您应承担赔偿责任；
       <br />4、您负责安排货物的装车和卸车工作，有责任为承运车辆营造良好的服务环境，如您及您的工作人员在货物承运过程中发生以下现象之一的，应承运车辆的赔偿要求，您应承担所有的违约金和相应的责任：
       <br />（1）不按预约时间装卸货物。
       <br />（2）装卸货物当中有野蛮装卸行为，承运车辆指出，您及您的工作人员不及时更改。
       <br />（3）您发错货，造成承运车辆承运货物到达目的地后，收货人拒收，由此产生的一切费用由您承担。
       <br />5、完成装卸货后，您有义务为承运车辆开具具有法律效力且注明所承运货物详细清单的出入库凭证。若有货损货差，您应要求承运车辆立即作出赔偿，赔偿标准由双方协商达成，若已购买货运保险及时联系向保险公司理赔，本网站在力所能及的范围内为您提供服务，减少您的经济损失。因下列原因造成的损失，承运方不承担赔偿责任：
       <br />（1）属于政府征用或罚没的；
       <br />（2）不可抗力；
       <br />（3）货物本身的性质所引起的变质、减量、破坏或毁灭；
       <br />（4）包装方式或容器不良，但从外部无法发现； 
       <br />（5）包装完整、标志无异状，而内件短少； 
       <br />（6）货物的合理损耗；
       <br />（7）托运人或收货人的过错。
       
       <br />六、E运通及其APP在用户交易中的作用及性质
       <br />E运通及APP仅作为用户发布信息、物色交易对象、就服务的交易进行协商、以及获取各类相关服务的网络平台。但是，本公司不能控制交易所涉及的物品/服务的质量、安全或合法性，信息的真实性或准确性，以及交易方履行其在协议项下的各项义务的能力。本公司不能也不会控制交易各方能否履行协议义务，作为E运通的车主用户和货主用户，需对利用本网络平台发生的交易承担所有责任，本公司无需对用户的交易行为、履约资信承担任何可能的法律责任。
       <br />所有E运通及APP用户，只有在理解并同意本协议第六条的规定后，才能成为E运通及APP用户，不同意者，请务必不要成为E运通及APP用户，一旦注册成为E运通及APP用户，就视为注册者同意了本协议所有条款之约定。
       
       <br />七、您的资料及交易物品
       <br />   “您的资料”包括您在注册、交易或列举物品/服务过程中向本公司或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任，而本公司仅作为您在网上发布和刊登“您的资料”的被动渠道。但是，倘若本公司认为“您的资料”可能使本公司承担任何法律或道义上的责任，或可能使本公司 (全部或部份地) 失去本公司的互联网服务供应商或其他供应商的服务，或您未在E运通规定的期限内登录或再次登录网站，则本公司可自行全权决定对“您的资料”采取本公司认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给E运通的“您的资料”拥有全部权利，包括全部版权。您确认，E运通没有责任去认定或决定您提交给本公司的资料哪些是应当受到保护的，对享有“本服务”的其他用户使用“您的资料”，本公司也不必负责。为了保证交易的安全进行，E运通有义务向货主提供车主在E运通注册时提交的包括但不限于 行驶证、营运证、司机、车主及车辆照片。
       
       <br />7.1 注册义务
       <br />如您在E运通注册，您同意：(a) 根据E运通刊载的会员资料表格的要求，提供关于您或贵公司的真实、准确、完整和反映当前情况的资料；(b) 维持并及时更新会员资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或E运通有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，E运通有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来对“服务”(或其任何部份) 以任何形式使用。如您代表一家公司或其他法律主体在本公司登记，则您声明和保证，您有权使该公司或其他法律主体受本协议“条款”约束。
       
       <br />7.2 会员注册名、密码和保密
       <br />在登记过程中，您将选择会员注册名和密码。您须自行负责对您的会员注册名和密码保密，且须对您在会员注册名和密码下发生的所有活动承担责任。您同意：(a) 如发现任何人未经授权使用您的会员注册名或密码，或发生违反保密规定的任何其他情况，您会立即通知E运通；(b) 确保您在每个上网时段结束时，以正确步骤离开网站。E运通不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。
       
       <br />7.3 关于您的资料的规则
       <br />您同意，“您的资料”和您提供在E运通上交易的任何“物品”（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义）
       <br />     a. 不会有欺诈成份；
       <br />     b. 不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权；
       <br />     c. 不会违反任何法律、法规、条例或规章 (包括但不限于保护消费者、不正当竞争或虚假广告的法律、法规、条例或规章)；
       <br />     d. 不会含有诽谤（包括商业诽谤）、非法恐吓或非法骚扰的内容；
       <br />     e. 不会含有淫秽、或包含任何儿童色情内容；
       <br />     f. 不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序；
       <br />     g. 不会直接或间接与下述各项服务连接，或包含对下述各项货物或服务的描述：(i) 本协议项下禁止的货物或服务；(ii) 您无权连接或包含的货物或服务。此外，您同意不会：在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“本服务”；未经其他人士同意，利用“本服务”收集其他人士的电话号码及其他资料；或利用“本服务”制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士。
       
       <br />八、您授予本公司的许可使用权
       <br />    您授予本公司独家的、国内通用的、永久的、免费的许可使用权利 (并有权在多个层面对该权利进行再授权)，使本公司有权(全部或部份地) 使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。
       
       <br />九、隐私
       <br />    本公司将仅根据本公司的隐私声明使用“您的资料”。本公司隐私声明的全部条款属于本协议的一部份，因此，您必须仔细阅读。请注意，您一旦自愿地在E运通交易地点披露“您的资料”，该等资料即可能被其他人士获取和使用。您同意将手机的地理位置分享给本网站，本网站有权利在认为必要的时候开放给其他第三方。
       
       <br />十、交易程序
       <br /> 10.1 添加产品/服务描述条目
       <br />      产品/服务描述是由您提供的在E运通上展示的文字描述、图画和/或照片，可以是(a) 对您拥有而您希望出售的产品/服务的描述；或 (b) 对您正寻找的产品/服务的描述。您可在E运通发布任一类产品/服务描述，或两种类型同时发布，条件是，您必须将该等产品/服务描述归入正确的类目内。E运通不对产品/服务描述的准确性或内容负责。
       
       <br /> 10.2 就交易进行协商
       <br />      交易各方通过在E运通上明确描述报价和回复，进行相互协商。所有各方接纳报价或回复将使所涉及的E运通用户有义务完成交易。除非在特殊情况下，诸如用户在您提出报价后实质性地更改对物品的描述或澄清任何文字输入错误，或您未能证实交易所涉及的用户的身份等，报盘和承诺均不得撤回。
       
       <br /> 10.3 处理交易争议
       <br />      本公司不会且不能牵涉进交易各方的交易当中。倘若您与一名或一名以上用户，或与您通过本公司网站获取其服务的第三者服务供应商发生争议，您免除E运通 (及本公司代理人和雇员) 在因该等争议而引起的，或在任何方面与该等争议有关的不同种类和性质的任何 (实际和后果性的) 权利主张、要求和损害赔偿等方面的责任。
       
       <br /> 10.4 运用常识
       <br />      本公司不能亦不试图对其他用户通过“本服务”提供的资料进行控制。就其本质而言，其他用户的资料，可能是令人反感的、有害的或不准确的，且在某些情况下可能带有错误的标识说明或以欺诈方式加上标识说明。本公司希望您在使用本公司网站时，小心谨慎并运用常识。
       
       <br />十一、信息反馈
       <br /> 11.1、反馈
       <br />       您不得采取任何可能损害信息反馈系统的完整性的行动，诸如：利用第二会员身份标识或第三者为您本身留下正面反馈；利用第二会员身份标识或第三者为其他用户留下负面反馈 (反馈数据轰炸)；或在用户未能履行交易范围以外的某些行动时，留下负面的反馈 (反馈恶意强加)。
       
       <br /> 11.2、不作商业性利用
       <br />       您同意，您不得对任何资料作商业性利用，包括但不限于在未经E运通事先书面批准的情况下，复制在E运通上展示任何资料。
       
       <br />十二、 终止或访问限制
       <br />      您同意，E运通可自行全权决定以任何理由 (包括但不限于E运通认为您已违反本协议的字面意义和精神，或以不符合本协议的字面意义和精神的方式行事，或您在超过90天的时间内未以您的帐号及密码登录网站) 终止您的“本服务”密码、帐户 (或其任何部份) 或您对“本服务”的使用，并删除和丢弃您在使用“本服务”中提交的“您的资料”。E运通同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供“本服务”或其任何部份。您同意，根据本协议的任何规定终止您使用“本服务”之措施可在不发出事先通知的情况下实施，并承认和同意，E运通可立即使您的帐户无效，或撤销您的帐户以及在您的帐户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或“服本务”。帐号终止后，E运通没有义务为您保留原帐号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，您同意，E运通不会就终止您接入“本服务”而对您或任何第三者承担任何责任。第11、12、13、20条应在本协议终止后继续有效。
       
       <br />十三、 违反规则会有什么后果？
       <br />      在不限制其他补救措施的前提下，发生下述任一情况，本公司可立即发出警告，暂时中止、永久中止或终止您的会员资格，删除您的任何现有信息，以及您在网站上展示的任何其他资料：(i) 您违反本协议；(ii) 本公司无法核实或鉴定您向本公司提供的任何资料；或 (iii) 本公司相信您的行为可能会使您、本公司用户或通过本公司或本公司网站提供服务的第三者服务供应商发生任何法律责任。在不限制任何其他补救措施的前提下，倘若发现您从事涉及本公司网站的诈骗活动，E运通可暂停或终止您的帐户。
       <br />      本公司会尽一切努力使您在使用E运通的过程中得到乐趣。遗憾的是，本公司不能随时预见到任何技术上的问题或其他困难。该等困难可能会导致数据损失或其他服务中断。为此，您明确理解和同意，您使用“本服务”的风险由您自行承担。“本服务”以“按现状”和“按可得到”的基础提供。E运通明确声明不作出任何种类的所有明示或暗示的保证，包括但不限于关于适销性、适用于某一特定用途和无侵权行为等方面的保证。E运通对下述内容不作保证：(i)“本服务”会符合您的要求；(ii)“本服务”不会中断，且适时、安全和不带任何错误；(iii) 通过使用“本服务”而可能获取的结果将是准确或可信赖的；及 (iv) 您通过“本服务”而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用“本服务”而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您通过“本服务”获取的任何口头或书面意见或资料，均不产生未在本协议内明确载明的任何保证。
       
       <br />十四、 责任范围
       <br />      您明确理解和同意，E运通不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿(无论E运通是否已被告知该等损害赔偿的可能性)：(i) 使用或未能使用“本服务”；(ii) 因通过或从“本服务”购买或获取任何货物、样品、数据、资料或服务，或通过或从“本服务”接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；(iii) 未经批准接入或更改您的传输资料或数据；(iv) 任何第三者对“服务”的声明或关於“服务”的行为；或 (v) 因任何原因而引起的与“服务”有关的任何其他事宜，包括疏忽。
       
       <br />十五、赔偿
       <br />     您同意，因您违反本协议或经在此提及而纳入本协议的其他文件，或因您违反了法律或侵害了第三方的权利，而使第三方对本公司及其子公司、分公司、董事、职员、代理人提出索赔要求（包括司法费用和其他专业人士的费用），您必须赔偿给本公司及其子公司、分公司、董事、职员、代理人，使其等免遭损失。
       
       <br />十六、遵守法律
       <br />     您应遵守与您使用“本服务”，以及与您购买和销售任何物品以及提供交易信息有关的所有相关的法律、法规、条例和规章。
       
       <br />十七、无代理关系
       <br />     您与E运通仅为独立订约人关系。本协议无意结成或创设任何代理、合伙、合营、雇用与被雇用或特许权授予与被授予关系。
       
       <br />十八、广告服务
       <br />     您与在“本服务”上或通过“本服务”物色的刊登广告人士通讯或进行业务往来或参与其推广活动，包括就相关货物或服务付款和交付相关货物或服务，以及与该等业务往来相关的任何其他条款、条件、保证或声明，仅限于在您和该刊登广告人士之间发生。您同意，对于因任何该等业务往来或因在“服务”上出现该等刊登广告人士而发生的任何种类的任何损失或损毁，E运通无需负责或承担任何责任。
       
       <br />十九、链接
       <br />     “本服务”或第三者均可提供与其它网站或资源的链接。由于E运通并不控制其它网站和资源，您承认并同意，E运通并不对其它外在网站或资源的可用性负责，且不认可其它网站或资源上或可从其它网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步承认和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，E运通均不承担责任。
       
       <br />二十、通知
       <br />     除非另有明确规定，任何通知应以APP推送或手机短信的形式发送， 发送到您在登记过程中向E运通提供的电子邮件地址或手机号。在推送或短信发出二十四 (24) 小时后，通知应被视为已送达。
       
       <br />二十一、不可抗力
       <br />     对于因本公司合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使本公司延迟或未能履约的，E运通不对您承担任何责任。
       
       <br />二十二、 转让
       <br />     本公司转让本协议无需经您同意。
       
       <br />二十三、其他规定
       <br />     本协议构成您和本公司之间的全部协议，规定了您对“本服务”的使用，并取代您和E运通先前订立的任何书面或口头协议。本协议各方面应受中华人民共和国大陆地区法律的管辖。倘若本协议任何规定被裁定为无效或不可强制执行，该项规定应被撤销，而其余规定应予执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。本公司未就您或其他人士的某项违约行为采取行动，并不表明本公司撤回就任何继后或类似的违约事件采取行动的权利。
       
       <br />二十四、争议的解决
       <br />     因本协议或本公司服务所引起或与其有关的任何争议，如协商不成，应当提交易运通(天津)信息技术有限公司所在地法院诉讼解决。
       
       </div>
       </div>
    );
  }
}
