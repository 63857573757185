import React from "react";
import { enquireScreen } from "enquire-js";

import Content0 from "./Content0";
import Content1 from "./Content1";
import Content2 from "./Content2";
import Customer from "./Customer";
import { BackTop, Row, Col } from "antd";

import solution1 from "../../../img/solution/solution1.png";
import solution2 from "../../../img/solution/solution2.png";
import solution3 from "../../../img/solution/solution3.png";
import solution4 from "../../../img/solution/solution4.png";
import solution5 from "../../../img/solution/solution5.png";

import "./less/antMotion_style.less";

let isMobile;
enquireScreen(b => {
  isMobile = b;
});

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile
    };
  }

  componentDidMount() {
    // 适配手机屏幕;
    enquireScreen(b => {
      this.setState({ isMobile: !!b });
    });
  }

  render() {
    const children = [
      <Content0
        id="content_2_0"
        key="content_2_0"
        isMobile={this.state.isMobile}
      />,
      <Content1
        id="content_3_0"
        key="content_3_0"
        isMobile={this.state.isMobile}
      />,
      <Content2
        id="content_2_1"
        key="content_2_1"
        isMobile={this.state.isMobile}
      />
    ];
    return (
      <div className="templates-wrapper">
        <Customer />
        <div className="solution-bg">
          <Row style={{ margin: "10vh auto", textAlign: "center" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 3 }}
            >
              <img src={solution1} />
              <span>车辆实时监控</span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution2} />
              <span>运单智能配载</span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution3} />
              <span>司机车辆集中管理</span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution4} />
              <span>财务自动核算</span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 6 }}
              lg={{ span: 3, offset: 1 }}
            >
              <img src={solution5} />
              <span>运输全程管理</span>
            </Col>
          </Row>
        </div>
        {children}
        <BackTop />
      </div>
    );
  }
}
