import React from "react";
import "./park.less";
import { Row, Col } from "antd";
import park1 from "../../img/solution/park1.png";
import park2 from "../../img/solution/park2.png";
import park4 from "../../img/solution/park4.png";
import ppt1 from "../../img/solution/ppt1.png";
import ppt2 from "../../img/solution/ppt2.png";

export default class Park extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div style={{ width: "100%" }}>
        <img src={park1} className="park-img-bg" />
        <Row
          style={{ padding: "64px 24px", height: "100%", overflow: "hidden" }}
        >
          <Row style={{ maxWidth: "1200px", margin: "0 auto" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <p style={{ fontSize: "28px", fontWeight: "bold" }}>
                减少中间环节
              </p>
              <span>
                应用信息整合与共享使物流干系人，应用平台手段，高效的信息传输，标准的流程管理，应用IT工具的智能化应用减少物流应用环节。
              </span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6, offset: 6 }}
            >
              <p style={{ fontSize: "28px", fontWeight: "bold" }}>
                综合物流管理
              </p>
              <span>
                通过平台产品的应用，对商流、物流、资金流、信息流进去全面的综合管理协同，保障相互简的衔接控制。通过平台手段使得所以物流业务标准化，实现企业SOP,同时通过平台技术提升客户用户体验。
              </span>
            </Col>
          </Row>
          <Row style={{ maxWidth: "1200px", margin: "20px auto" }}>
            <img src={ppt1} style={{ width: "100%" }} />
          </Row>
          <Row style={{ maxWidth: "1200px", margin: "0 auto" }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6, offset: 6 }}
            >
              <p style={{ fontSize: "28px", fontWeight: "bold" }}>
                降低物流成本
              </p>
              <span>
                通过平台的高效应用，较少人工操作，通过大数据应用的智能分析减少运作过程的预警、报警的控制。通过信息对称手段减少沟通成本和其他边际成本
              </span>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6, offset: 6 }}
            >
              <p style={{ fontSize: "28px", fontWeight: "bold" }}>
                安全风险控制
              </p>
              <span>
                通过平台手段，为园区内客户、驻户、合作伙伴等，平台将提供安全保障的体验，通过平台可视化管理机制监督和管控物流全过程，保障交易安全、运作安全、异常事务风险控制
              </span>
            </Col>
          </Row>
        </Row>
        <img src={park2} className="park-img-bg" />
        <Row
          style={{ padding: "64px 24px", height: "100%", overflow: "hidden" }}
        >
          <img src={ppt2} className="park-img-bg" />
        </Row>
        <img src={park4} className="park-img-bg" />
      </div>
    );
  }
}
