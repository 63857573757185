import React from 'react';
import './less/content3n.less'
class Content extends React.Component {

  render() {
    return (
      <div className="map-bg">
        <div className="map-position">
        <div className="map-box">
                <div className="address-company">
                    <h3>易运通（天津）信息技术有限公司</h3>
                    <p>地址：北京市昌平区回龙观东大街首开广场405-3</p>
                    <p>电话：400-698-5656</p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}


export default Content;
