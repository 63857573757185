import React from 'react';
import './solution.less'
import topbg from '../../../img/solution/top.jpg'
import topText from '../../../img/solution/top-text.png'
class Customer extends React.Component {

  render() {
    return (
            <div className="title_bj">
                <div className="sec-top">
                    <img  className="solve-bj" src={topbg} alt="产品功能" style={{marginLeft: 0}} />
                    <div className="top-bg-content">
                        <img  className="top-text-img" src={topText}  style={{marginLeft: 0}} />
                        <span className="top-text">通过ETMS系统+手机客户端+ETMS查询系统，全面解决您的痛点、难点</span>
                    </div>
                </div>
            </div>
    );
  }
}


export default Customer;
