import React from 'react';
import './less/content0n.less'
class Content extends React.Component {

  render() {
    return (
      <div className="top-banner-bg">
        <div className="top-position">
            <div className="top-box">
                <div className="title">
                    <h5>E运通介绍</h5>
                </div>
                <div className="comp-info">
                    <p>
                        2018年初，易运通（天津）信息技术有限公司成立，标志着物流行业“新互联网物流时代”的开启。易运通旗下的ETMS系统，作为“互联网+”高效物流的踏实践行者，致力于提升中国数十万家物流公司的信息化水平。借助协同型物流云SaaS平台+移动端产品，解决从开单到签收全流程的运单流转问题，未来重点为物流公司提供信息流、物流、资金流三流合一的一站式解决方案。</p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}


export default Content;
