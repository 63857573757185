import React from "react";
import { Row, Col } from "antd";
import customer1 from "../../img/Home/1.png";
import customer2 from "../../img/Home/2.png";
import customer3 from "../../img/Home/3.png";
import customer4 from "../../img/Home/4.png";
import "./less/content50.less";
class Content50 extends React.PureComponent {
  render() {
    return (
      <div className="customer-container">
        <div className="customer-content">
          <span className="customer-title" id="partner">
            合作伙伴
          </span>
          <p className="title-black-line" />
          <Row style={{ marginTop: "40px" }}>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img class="customer-img" src={customer1} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img class="customer-img" src={customer2} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img class="customer-img" src={customer3} />
              </div>
            </Col>
            <Col
              className="customer-item-box"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 6 }}
            >
              <div className="customer-item">
                <img class="customer-img" src={customer4} />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default Content50;
