import React from 'react';
import './solution.less'
import topbg from '../../../img/solution/3pltop.jpg'
import topText from '../../../img/solution/top-text2.png'
class Forwarder extends React.Component {

  render() {
    return (
      <div className="title_bj">
          <div className="sec-top">
              <img  className="solve-bj" src={topbg} alt="产品功能" style={{marginLeft: 0}} />
              <span className="top-content"></span>
              <div className="top-bg-content">
                  <img  className="top-text-img" src={topText}  style={{marginLeft: 0}} />
                  <span className="top-text">ETMS系统+承运商端+司机端+小程序+ETMS查询系统</span>
              </div>
          </div>
      </div>
    );
  }
}


export default Forwarder;
